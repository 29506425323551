import { useEffect, useState, useContext, useCallback } from "react";
import { Link } from "react-router-dom";

import { getMethodenData } from "../api/methoden-api";

import AppContext from "../context/AppContext";
import Menu from "../components/commons/Menu";
import ComponentTypes from "../constants/ComponentTypes";

import "../components/methoden/styles.scss";

function MethodenPage() {
  const [methodenItems, setMethodenItems] = useState([]);
  const context = useContext(AppContext);

  const { isMobile } = context;

  const setData = useCallback(async () => {
    const data = await getMethodenData(context.store.language);
    setMethodenItems(data);
  }, [context.store.language]);

  useEffect(() => {
    setData();
  }, [setData])

  const methoden = methodenItems[0]?.paragraphsWithTitlesCollection.items.map((item, index) => (
    <div className={`methoden--item-${index + 1}`} key={`methoden-item-${index + 1}`}>
      <div className="page-title color-light">{item.title}</div>
      <p className="methoden-subtitle text"><strong>{item.subtitle}</strong></p>
      {item.paragraphText?.json.content.map((cont, index) => <p className="methoden--paragraph text" key={`methoden--paragraph-${index}`}>{cont.content[0].value}</p>)}
    </div>
  ));

  const methodenLeftColumn = (
    <>
      {isMobile ? methoden : methoden?.slice(0, 3)}
      {!isMobile &&
        <div className="button-container">

          <button className="green-button">
            <Link to="/" state={{ id: "kontaktieren" }} className="link-cta">{methodenItems[0]?.buttonLabel}</Link>
          </button>
        </div>
      }
    </>
  );

  const methodenRightColumn = isMobile ? methoden?.slice(2, 3) : methoden?.slice(3);

  return (
    <div className={`methoden--page page ${isMobile ? "" : "bg-top-reverse"}`} id="methoden">
      <Menu componentType={ComponentTypes.ME} returnLink={"therapie-methoden-return"} />
      <div className="methoden--container page-content text-green">
        <div className="methoden--image-container">
          <div className="methoden--left">
            {methodenLeftColumn}
          </div>
          <div className="methoden--right">
            {methodenRightColumn}
          </div>
        </div>
        {isMobile &&
          <button className="green-button">
            <Link to="/" state={{ id: "kontaktieren" }} className="link-cta mobile-link-cta">{methodenItems[0]?.buttonLabel}</Link>
          </button>
        }
      </div>
    </div>
  )

}

export default MethodenPage;
import { useCallback, useContext, useEffect, useState } from "react";

import { getPreislisteCardsData, getPreislisteSubcardsData, getPreislisteInfoData } from "../api/preisliste-api";

import AppContext from "../context/AppContext";

import Card from "../components/commons/Card";
import { ReactComponent as PriceInfoIcon } from "../icons/price-info.svg";

import "../components/preisliste/styles.scss";

function Preisliste() {
  const [preislisteCards, setPreislisteCards] = useState([]);
  const [preislisteSubcards, setPreislisteSubcards] = useState([]);
  const [preislisteInfo, setPreislisteInfo] = useState([]);
  const context = useContext(AppContext);
  const { isMobile } = context;

  const setData = useCallback(async () => {
    const cardsData = await getPreislisteCardsData(context.store.language);
    const subcardsData = await getPreislisteSubcardsData(context.store.language);
    const infoData = await getPreislisteInfoData(context.store.language);
    setPreislisteCards(cardsData);
    setPreislisteSubcards(subcardsData);
    setPreislisteInfo(infoData)
  }, [context.store.language]);

  useEffect(() => {
    setData();
  }, [setData])

  const scroll = (elementId) => {
    const section = document.querySelector(`#${elementId}`);
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };


  const preislisteCardItems = preislisteCards[0]?.priceCardsCollection?.items.map((card, index) => (
    <div className={`preisliste--card preisliste--card-${index + 1}`} key={`preisliste--card -${index}`}>
      <Card
        list={card.list}
        cardDetails={card.cardDetails}
        price={card.price}
        priceDetails={card.priceDetails}
      />
    </div>
  ));
  const preislisteSubcardItems = preislisteSubcards[0]?.priceSubcardsCollection?.items.map((subcard, index) => (
    <Card
      className={`preisliste--subcard preisliste--subcard-${index + 1}`}
      title={subcard.title}
      list={subcard.list}
      key={`preisliste--subcard-${index}`}
    />
  ));

  const preislisteItems = preislisteCardItems && preislisteSubcardItems && preislisteCardItems.map((item, index) => (
    <div className="preisliste--item-wrapper" key={`preiliste--item-${index}`}>
      <p className="preisliste--item-title text-large text-dark-green">{preislisteCards[0]?.priceCardsCollection?.items[index].title}</p>
      <div className={`preisliste--item preisliste--item-${index + 1}`} key={`preisliste--item-${index}`}>
        {preislisteCardItems[index]}
        {preislisteSubcardItems[index]}
      </div>
    </div>
  ));

  return (
    <div className={`preisliste--container page ${isMobile ? "" : "bg-bot"}`} id="preisliste">
      <div className="page-content">
        <div className="preisliste--title page-title text-right color-light">{preislisteCards[0]?.pageTitle}</div>
        <div className="preisliste--items-container">{preislisteItems}</div>
        <div className="preisliste--price-info-container">
          <PriceInfoIcon className="preisliste--price-info-icon" />
          <div>
            <Card
              className="preisliste--info"
              titleClassName="preisliste--title"
              title={preislisteInfo[0]?.infoCard.title}
              list={preislisteInfo[0]?.infoCard.list}
            />
            <button className="preisliste--booking-button green-button" onClick={() => scroll("kontaktieren")}>{preislisteInfo[0]?.bookingButtonLabel}
            </button>
          </div>
        </div>
      </div>
    </div>
  )

}

export default Preisliste;
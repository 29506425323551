import { useState, useEffect, useContext, useCallback } from "react";

import { getTierPhysioData } from "../api/tier-physio-api";

import AppContext from "../context/AppContext";

import Card from "../components/commons/Card";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Pagination, Navigation } from "swiper";

import 'swiper/css';
import 'swiper/css/navigation';
import "swiper/css/pagination";
import "../components/tierPhysio/styles.scss";


function TierPhysio() {
  const [tierPhysioData, setTierPhysioData] = useState([]);
  const context = useContext(AppContext);
  const { isMobile } = context;

  const setData = useCallback(async () => {
    const data = await getTierPhysioData(context.store.language);
    setTierPhysioData(data);
  }, [context.store.language]);

  useEffect(() => {
    setData();
  }, [setData])

  return (
    <div className={`tier-physio--container page ${isMobile ? "" : "bg-top"}`} id="tier-physio">
      <div className="page-content">
        <div className="tier-physio__content">
          <h1 className="tier-physio--title page-title color-light">{tierPhysioData[0]?.title}</h1>
          <p className="tier-physio--subtitle text">{tierPhysioData[0]?.subtitle}</p>
        </div>

        <Swiper
          spaceBetween={42}
          slidesPerView={isMobile ? 1 : 4}
          keyboard={{
            enabled: true,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Keyboard, Pagination, Navigation]}>
          {tierPhysioData[0]?.cardCollection.items.map((item, i) =>
            <SwiperSlide key={i}>
              <Card title={item.title} subtitle={item.subtitle} list={item.list} />
            </SwiperSlide>)}
        </Swiper>
      </div>

    </div>
  )
}

export default TierPhysio;
import { useEffect, useState } from "react";
import { useSentenceDict } from "../hooks/useSentence";
import AppContext, { defaultContextValues } from "./AppContext";
import Languages from "../constants/Languages";

function Provider({ children }) {
  const [store, updateStore] = useState(defaultContextValues);
  const [width, setWidth] = useState(window.innerWidth);

  const { allSentencesDict } = useSentenceDict();

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    const currentLanguage = localStorage.getItem("currentLanguage");
    updateStore((state) => ({ ...state, language: currentLanguage || Languages.DE }));

    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const switchLanguage = (language) => {
    localStorage.setItem("currentLanguage", language);
    updateStore((state) => ({ ...state, language: language }));
  }

  const getSentence = (sentence) => {
    if (store.language === Languages.DE) {
      return sentence;
    }
    return allSentencesDict[sentence];
  }

  const providerData = {
    store,
    switchLanguage,
    getSentence,
    isMobile: width <= 1280
  }

  return <AppContext.Provider value={providerData}>{children}</AppContext.Provider>
}

export default Provider;
import { getData } from "../helpers/data";

export async function getPreislisteCardsData(lang) {
  const query = `
    {
        preislisteCardsCollection(locale: "${lang}") {
            items {
              pageTitle
              priceCardsCollection {
                items {
                  title
                  subtitle
                  list
                  cardDetails
                  price
                  priceDetails
                }
              }
            }
          }
    }
`
  const data = await getData(query);
  return data.data.preislisteCardsCollection.items;
}

export async function getPreislisteSubcardsData(lang) {
  const query = `
    {
        preislisteSubcardsCollection(locale: "${lang}") {
            items {
              priceSubcardsCollection {
                items {
                  title
                  list
                }
              }
            }
          }
    }
`
  const data = await getData(query);
  return data.data.preislisteSubcardsCollection.items;
}

export async function getPreislisteInfoData(lang) {
  const query = `
  {
      preislisteInfoCollection(locale: "${lang}") {
        items {
            infoCard {
              title
              list
            }
            bookingButtonLabel
        }
      }
  }
`
  const data = await getData(query);
  return data.data.preislisteInfoCollection.items;
}
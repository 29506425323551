import { useCallback, useContext, useEffect, useState } from "react";
import { getCoreValuesData } from "../api/core-values-api";
import AppContext from "../context/AppContext";
import "../components/coreValues/styles.scss";

function CoreValues() {
  const [coreValuesItems, setCoreValuesItems] = useState([]);
  const context = useContext(AppContext);
  const { isMobile } = context;

  const setData = useCallback(async () => {
    const data = await getCoreValuesData(context.store.language);
    setCoreValuesItems(data);
  }, [context.store.language])

  useEffect(() => {
    setData();
  }, [setData])

  const coreValues = coreValuesItems[0]?.paragraphsWithTitlesCollection.items.map((item, index) => (
    <div className={`core-values--item core-values--item-${index + 1}`} key={`core-value--item-${index}`}>
      <div className="core-values--item-title">{item.title}</div>
      <div className="core-values--item-content text">
        {!isMobile && <div className="line-arrow"></div>}
        {item.paragraphText?.json.content.map(cont => <div className="text" dangerouslySetInnerHTML={{ __html: cont.content[0].value }} />)}
      </div>
    </div>
  ));

  const coreValuesLeftColumn = coreValues?.slice(0, 3);
  const coreValuesRightColumn = coreValues?.slice(3);

  return (
    <>
      <div className="core-values--container page" id="core-values">
        <div className="core-values--title page-title color-dark">{coreValuesItems[0]?.title}</div>
        <div className="core-values--content page-content">
          <div className="core-values--left">
            {coreValuesLeftColumn}
          </div>
          <div className="core-values--right">
            {coreValuesRightColumn}
          </div>
        </div>
      </div>
      <div className="core-values--bottom" />
    </>
  )
}

export default CoreValues;
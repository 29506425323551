import {useContext} from "react";
import AppContext from "../../context/AppContext";
import Languages from "../../constants/Languages";

import "./languageSwitch/styles.scss";

function LanguageSwitch() {
  const context = useContext(AppContext);
  const lang = context.store.language;

  const onLanguageSwitch = (language) => {
    context.switchLanguage(language);
  }
  
  return (
    <div className="language-switch">
      <button onClick={() => onLanguageSwitch(Languages.DE)} className={lang === Languages.DE ? "active" : ""}>DE</button>
      <span>|</span>
      <button onClick={() => onLanguageSwitch(Languages.EN)} className={lang === Languages.EN ? "active" : ""}>EN</button>
    </div>
  )
}

export default LanguageSwitch;
import { getData } from "../helpers/data";

export async function getTherapieMethodenData(lang) {
  const query = `
    {
        therapieMethodenCollection(locale: "${lang}") {
            items {
                title
                paragraph
                buttonLabel
                methodsList
            }
        }
    }
`
  const data = await getData(query);
  return data.data.therapieMethodenCollection.items;
}
import { useState, useContext, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";

import AppContext from "../context/AppContext";
import { getWelcomeData } from "../api/willkomen-api";

import "../components/willkomen/styles.scss";

function Willkomen() {
  const [wilkomenData, setWilkomenData] = useState([]);
  const context = useContext(AppContext);
  const { isMobile } = context;

  const setData = useCallback(async () => {
    const data = await getWelcomeData(context.store.language);
    setWilkomenData(data);
  }, [context.store.language]);

  useEffect(() => {
    setData();
  }, [setData]);

  const scroll = (elementId) => {
    const section = document.querySelector(`#${elementId}`);
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <div className="willkomen--content page text-dark-green" id="willkomen">
      <div className="willkomen--left">
        {!isMobile && <h1 className={`page-title color-light ${isMobile ? "" : "text-right"}`}>{wilkomenData?.leftTitle}</h1>}
        <div className={`willkomen--section-left text ${isMobile ? "" : "text-right"}`}>
          {wilkomenData?.leftDescription?.json.content.map((cont, i) => <p key={i}>{cont.content[0].value}</p>)}
          <div className="willkomen--buttons">
            <Link to="/shop" className="green-button">{context.getSentence("Zum shop")}</Link>
            <button className="green-button" onClick={() => scroll("kontaktieren")}>{context.getSentence("KONTAKT AUFNEHMEN")}</button>
          </div>
        </div>
      </div>
      <div className="willkomen--right">
        <div className="willkomen--info">
          {isMobile && <h1 className={`page-title color-light ${isMobile ? "" : "text-right"}`}>{wilkomenData?.leftTitle}</h1>}
          {!isMobile && <h2 className="page-title color-light">{wilkomenData?.rightTitle}</h2>}
          <div className="willkomen--section-right text">
            {wilkomenData?.rightDescription?.json.content.map((cont, i) => <p key={i}>{cont.content[0].value}</p>)}
          </div>
        </div>
        <img className="willkomen--img" src={wilkomenData?.image?.url} alt={wilkomenData?.image?.title} height={430} width={286} />
      </div>
    </div>
  );
}

export default Willkomen;
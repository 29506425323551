import { createContext } from "react";
import Languages from "../constants/Languages";

export const defaultContextValues = {
  language: Languages.DE,
  switchLanguage: () => {},
  isSideMenuOpen: false,
}

const AppContext = createContext(defaultContextValues);

export default AppContext;
import Provider from "./context/Provider";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import LandingPage from "./pages/LandingPage";
import ShopPage from "./pages/ShopPage";
import ImpressumPage from "./pages/ImpressumPage";
import MethodenPage from "./pages/MethodenPage";

import "./index.scss";

function App() {
  return (
    <Provider>
      <div className="app" style={{ height: "100vh", overflowX: "hidden" }}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/shop" element={<ShopPage />} />
            <Route path="/impressum" element={<ImpressumPage />} />
            <Route path="/methoden" element={<MethodenPage />} />
          </Routes>
        </BrowserRouter>
      </div>
    </Provider>
  );
}

export default App;

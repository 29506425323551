import ComponentTypes from "../../constants/ComponentTypes";
import { Link } from "react-router-dom";
import SideMenuTrigger from "./SideMenuTrigger";
import LanguageSwitch from "./LanguageSwitch";
import "./menu/styles.scss"
import { useContext } from "react";
import AppContext from "../../context/AppContext";

function Menu({ componentType, returnLink }) {
  const context = useContext(AppContext);

  const { isMobile } = context;

  return <div className="menu">
    {componentType === ComponentTypes.HP &&
      <Link to="/" className="logo">Diana Rusu</Link>
    }
    {(componentType === ComponentTypes.IM || componentType === ComponentTypes.SHOP || componentType === ComponentTypes.IM || componentType === ComponentTypes.ME) &&
      <Link to="/" state={{ id: returnLink }} className="menu__return link-cta">{context.getSentence("ZURÜCK")}</Link>
    }

    <div className="menu__right">
      {!isMobile &&
        <>
          {(componentType === ComponentTypes.CT || componentType === ComponentTypes.HP || componentType === ComponentTypes.SHOP || componentType === ComponentTypes.IM || componentType === ComponentTypes.ME) &&
            <>
              {componentType === ComponentTypes.HP && <LanguageSwitch />}
              {componentType !== ComponentTypes.SHOP && <Link to="/shop" className="link-cta">Shop</Link>}
              <Link to="/" state={{ id: "willkomen" }}>About me</Link>
            </>
          }
          {componentType === ComponentTypes.CT && <Link to="/impressum">Impressum</Link>}
        </>
      }
      {(componentType === ComponentTypes.HP || componentType === ComponentTypes.IM || componentType === ComponentTypes.SHOP || componentType === ComponentTypes.ME) &&
        <SideMenuTrigger />
      }
    </div>
  </div>
}


export default Menu;
import { useContext } from "react";
import { Link } from "react-router-dom";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import AppContext from "../../context/AppContext";
import FooterExtended from "./FooterExtended";
import ComponentTypes from "../../constants/ComponentTypes";
import LanguageSwitch from "./LanguageSwitch";

import "./sideMenuPopup/styles.scss";

function SideMenuPopup({ open, setOpen }) {
  const context = useContext(AppContext);

  return <Popup modal nested open={open} closeOnDocumentClick onClose={() => setOpen(false)} className="side_menu_popup">
    <div className="side-menu">
      <div className="side-menu__top">
        <div className="side-menu__button">
          <button className="side-menu--close green-button" onClick={() => setOpen(false)}>{context.getSentence("ZURÜCK")}</button>
        </div>
        <p className="text-green">{context.getSentence("ÜBERSICHT")}</p>
        <Link to="/shop" className="side-menu__button text-green">SHOP</Link>
      </div>
      <div className="side-menu--content">
        <Link to="/" state={{ id: "willkomen" }}>{context.getSentence("About me")}</Link>
        <Link to="/" state={{ id: "tier-physio" }}>{context.getSentence("Tierphysio")}</Link>
        <Link to="/" state={{ id: "therapie-methoden" }} >{context.getSentence("Therapiemethoden")}</Link>
        <Link to="/" state={{ id: "core-values" }}>{context.getSentence("Core values")}</Link>
        <Link to="/" state={{ id: "preisliste" }}>{context.getSentence("Meine Preise")}</Link>
        <Link to="/" state={{ id: "behandlungs-prozess" }}>{context.getSentence("Behandlungsprozess")}</Link>
        <Link to="/" state={{ id: "kontaktieren" }}>{context.getSentence("Kontakt")}</Link>
        <Link to="/impressum" className="link-cta">{context.getSentence("Impressum")}</Link>
      </div>
      <LanguageSwitch />
      <FooterExtended componentType={ComponentTypes.SM} />
    </div>
  </Popup>
}

export default SideMenuPopup;
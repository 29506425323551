import { getData } from "../helpers/data";

export async function getTierPhysioData(lang) {
  const query = `
    {
      physiotherapieCollection(locale: "${lang}") {
            items {
                title
                subtitle
                cardCollection {
                  items {
                    title
                    subtitle
                    list
                  }
                   
                }
            }
        }
    }
`
  const data = await getData(query);
  return data.data.physiotherapieCollection.items;
}
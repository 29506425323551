import "./card/styles.scss";

function Card({ className, titleClassName, title, subtitle, list, cardDetails, price, priceDetails, titleOutside }) {
  const cardClassName = className ? `card ${className}` : "card";
  
  return (
    <div className={cardClassName}>
      {title && <p className={`card-title text-green ${titleClassName}`}>{title}</p>}
      {subtitle && <p className="card-subtitle text-green">{subtitle}</p>}
      {list &&
        <ul className="card-list">
          {list.map((item, i) => <li className="text text-green" key={`list-${i}`}>{item}</li>)}
        </ul>
      }
      {cardDetails && <div className="text text-dark-grey">{cardDetails}</div>}
      {price &&
        <div className="card-price-container">
          <div className="card-price text-large text-dark-grey">{price}</div>
          {<div className="card-price-details text text-dark-grey">{priceDetails || ""}</div>}
        </div>
      }
    </div>
  )
}

export default Card;
import { useCallback, useContext, useEffect, useState } from "react";
import { getImpressumData } from "../api/impressum-api";
import Menu from "../components/commons/Menu";
import ComponentTypes from "../constants/ComponentTypes";
import AppContext from "../context/AppContext";

import "../components/impressum/styles.scss";

function ImpressumPage() {
  const context = useContext(AppContext);
  const [impressumItems, setImpressumItems] = useState([]);

  const { isMobile } = context;

  const setData = useCallback(async () => {
    const data = await getImpressumData();
    setImpressumItems(data);
  }, []);

  useEffect(() => {
    setData();
  }, [setData])

  if (!impressumItems) {
    return <div>Loading...</div>;
  }

  const impressumSections = impressumItems?.paragraphsWithTitlesCollection?.items.map((section, i) => (
    <div className="impressum-section" key={i}>
      <strong className="text">{section.title}</strong>
      <div className="impressum-section--secondary-subtitle text">{section.subtitle}</div>
      {section.paragraphText?.json.content.map(cont => <div className="text" dangerouslySetInnerHTML={{ __html: cont.content[0].value }} />)}
    </div>
  ));

  return (
    <div className={`impressum-content page ${isMobile ? "" : "bg-top-reverse"}`} id="impressum">
      <Menu componentType={ComponentTypes.IM} />
      <div className="impressum-sections page-content text-green">
        <div className="page-title text-left color-light">{impressumItems?.title}</div>
        {impressumSections}
      </div>
    </div>
  )
}

export default ImpressumPage;
import { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { getTherapieMethodenData } from "../api/therapie-methoden-api";
import AppContext from "../context/AppContext";
import "../components/therapieMethoden/styles.scss";

function TherapieMethoden({ therapieRef }) {
  const [therapieMethodenItems, setTherapieMethodenItems] = useState([]);
  const context = useContext(AppContext);
  const { isMobile } = context;

  const setData = useCallback(async () => {
    const data = await getTherapieMethodenData(context.store.language);
    setTherapieMethodenItems(data);
  }, [context.store.language]);

  useEffect(() => {
    setData();
  }, [setData])

  const therapyMethods = therapieMethodenItems[0]?.methodsList?.map((item, index) => (
    <div className="therapie-methoden--item" key={`therapie-methoden--item-${index}`}>{item}</div>
  ));

  return (
    <div className={`page ${isMobile ? "" : "bg-bot"}`} id="therapie-methoden" ref={therapieRef}>
      <div className="therapie-methoden--content page-content">
        <div className="therapie-methoden--left">
          <div className="bg-circle"></div>
          {therapyMethods}
        </div>
        <div className={`therapie-methoden--right ${isMobile ? "" : "text-right"}`}>
          <h1 className="page-title color-light">{therapieMethodenItems[0]?.title}</h1>
          <div className="therapie-methoden--subtitle text">
            <p>{therapieMethodenItems[0]?.paragraph}</p>
            <Link to="/methoden" className="link-cta">  <button className="therapie-methoden--button green-button">
              {therapieMethodenItems[0]?.buttonLabel}
            </button></Link>

          </div>
        </div>
      </div>
    </div>
  )
}

export default TherapieMethoden;
import { ReactComponent as DesignerLogo } from "../../icons/designer-logo.svg";
import SocialMediaLinks from "./SocialMediaLinks";
import "./footerExtended/styles.scss";
import Menu from "./Menu";
import ComponentTypes from "../../constants/ComponentTypes";

function FooterExtended({ componentType }) {
  return (
    <div className="extended-footer page-content">
      {componentType === ComponentTypes.CT &&
        <div className="extended-footer__designer-info">
          <p>Website designed by</p>
          <DesignerLogo />
          <a href="https://linktr.ee/laracz">Kontakt / Social Media Profile</a>
        </div>
      }
      <div className="extended-footer__social-menu">
        <SocialMediaLinks width={19} height={19} />
        <Menu componentType={componentType} />
      </div>

    </div>
  )

}

export default FooterExtended;
import axios from "axios";

export function getData(query) {
  const promise = axios.post(`https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/`, JSON.stringify({ query }), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}`,
    }
  })
  const dataPromise = promise.then((response) => response.data)

  return dataPromise;
}
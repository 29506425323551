import { useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';

import Home from "../sections/Home";
import Willkomen from "../sections/Willkomen";
import TierPhysio from "../sections/TierPhysio";
import TherapieMethoden from "../sections/TherapieMethoden";
import CoreValues from "../sections/CoreValues";
import Preisliste from "../sections/Preisliste";
import BehandlungsProzess from "../sections/BehandlungsProzess";
import Kontaktieren from "../sections/Kontaktieren";

function LandingPage() {
  const { state } = useLocation();
  const therapieRef = useRef(null);

  const scrollToSection = (section) => {
    setTimeout(() => {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 500)
  }

  useEffect(() => {
    if (state) {
      const section = document.querySelector(`#${state.id}`);
      if (state.id === 'therapie-methoden-return') {
        therapieRef.current.scrollIntoView();
      } else {
        scrollToSection(section);
      }
      window.history.replaceState({}, document.title)
    }
  }, [state])

  return (
    <>
      <Home />
      <Willkomen />
      <TierPhysio />
      <TherapieMethoden therapieRef={therapieRef} />
      <CoreValues />
      <Preisliste />
      <BehandlungsProzess />
      <Kontaktieren />
    </>
  )
}

export default LandingPage;
import { useContext, useState, useRef } from "react";
import emailjs from '@emailjs/browser';
import AppContext from "../context/AppContext";

import FooterExtended from "../components/commons/FooterExtended";
import ComponentTypes from "../constants/ComponentTypes";

import contactImage from "../images/contact-image.png";

import "../components/kontactieren/styles.scss";

function Kontaktieren({contactRef}) {
  const context = useContext(AppContext);
  const form = useRef();
  const [contactMessage, setContactMessage] = useState("");
  const { isMobile } = context;

  const OPTIONS = [
    {
      value: "",
      option: ""
    },
    {
      value: "question",
      label: context.getSentence("Ich habe eine Frage"),
    },
    {
      value: "treatment",
      label: context.getSentence("1. Behandlung"),
    },
    {
      value: "bundle1",
      label: context.getSentence("Bundle 1"),
    },
    {
      value: "bundle2",
      label: context.getSentence("Bundle 2"),
    },
    {
      value: "bundle3",
      label: context.getSentence("Bundle 3"),
    }
    , {
      value: "other",
      label: context.getSentence("Anderes")
    }
  ]

  const onSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
      .then((result) => {
        console.log("Email was sent: ", result.text);
        setContactMessage(context.getSentence("Thank you for reaching out!"));
      }, (error) => {
        console.log(error.text);
        setContactMessage(context.getSentence("Message could not be sent. Please try again!"))
      });
    e.target.reset();
  }

  return (
    <div className={`contact-page ${!isMobile ? "bg-bot-reverse" : ""}`} id="kontaktieren" ref={contactRef}>
      <div className="contact__content page-content">
        <p className="contact__content--title page-title color-light">{context.getSentence("KONTAKTIEREN SIE MICH")}</p>
        <div className="contact__content--wrapper">
          {!isMobile && <img src={contactImage} alt="Contact" />}
          <form onSubmit={onSubmit} ref={form}>
            <div className="contact__field">
              <label htmlFor="name" className="required text">{context.getSentence("Name/Nachname")}</label><br />
              <input type="text" id="name" name="name" required />
            </div>
            <div className="contact__field">
              <label htmlFor="contact_issue" className="text">{context.getSentence("Aliengen")}</label><br />
              <select name="issue" id="issue" >
                {OPTIONS.map((opt) => <option key={opt.value} value={opt.value}>{opt.label}</option>)}
              </select>
            </div>
            <div className="contact__field">
              <label htmlFor="email" className="required text">{context.getSentence("Email")}</label><br />
              <input type="email" id="email" name="email" required />
            </div>
            <div className="contact__field">
              <label htmlFor="phone" className="text">{context.getSentence("Telefonnummer")}</label>
              <input type="text" id="phone" name="phone" />
            </div>
            <div className="contact__field">
              <label htmlFor="message" className="required text">{context.getSentence("Nachricht")}</label>
              <textarea id="message" name="message" rows={isMobile ? 5 : 8} cols={isMobile ? 7 : 10} required />
            </div>
            <p className="message">{context.getSentence("Alle Bereiche mit „*“ müssen ausgefüllt werden")}</p>
            <input type="submit" className="green-button" value={context.getSentence("Senden")} />
            <p className="submit-message text">{contactMessage}</p>
          </form>
        </div>
      </div >

      <FooterExtended isExtendedVersion componentType={ComponentTypes.CT} />
    </div >
  )


}

export default Kontaktieren;
import { ReactComponent as InstagramIcon } from "../../icons/instagram.svg";
import { ReactComponent as WhatsappIcon } from "../../icons/whatsapp.svg";
import { ReactComponent as EmailIcon } from "../../icons/email.svg";

import "./socialMediaLinks/styles.scss";

function SocialMediaLinks({ width, height }) {

    return (
        <div className="social-media-links-container">
            <a target="_blank" href="https://www.instagram.com/artemis.tiertherapie/?igshid=YmMyMTA2M2Y%3D&fbclid=IwAR2r4INVxa38W2En8-ZAEkeT8NycnD0mZXyToi4u-RpWw5NJvXdyuos0Rxw" rel="noreferrer" className="social-media-link">
                <InstagramIcon width={width} height={height} />
            </a>
            <a target="_blank" href="https://wa.link/oeh4t7" rel="noreferrer" className="social-media-link">
                <WhatsappIcon width={width} height={height} />
            </a>
            <a target="_blank" href="mailto:artemis.tiertherapie@gmail.com" rel="noreferrer" className="social-media-link">
                <EmailIcon width={width} height={height} />
            </a>
        </div>
    )
}

export default SocialMediaLinks;
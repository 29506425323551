import { useCallback, useContext, useEffect, useState } from "react";
import { getBehandlungsProzessData } from "../api/behandlungs-prozess-api";
import AppContext from "../context/AppContext";
import "../components/behandlungs-prozess/styles.scss";

function BehandlungsProzess() {
  const [behandlungsProzessItems, setBehandlungsProzessItems] = useState([]);
  const context = useContext(AppContext);
  const { isMobile } = context;

  const setData = useCallback(async () => {
    const data = await getBehandlungsProzessData(context.store.language);
    setBehandlungsProzessItems(data);
  }, [context.store.language])

  useEffect(() => {
    setData();
  }, [setData]);

  const processItems = behandlungsProzessItems[0]?.paragraphsWithTitlesCollection.items.map((item, index) => (
    <div className={`process--item process--item-${index + 1}`}>
      <div className="process--item-number">{index + 1}</div>
      <div className="process--item-title">{item.title}</div>
      <p className="process--item-content text">
        {item.paragraphText?.json.content.map(cont => <div className="text" dangerouslySetInnerHTML={{ __html: cont.content[0].value }} />)}
      </p>
    </div>
  ));

  return (
    <div className="process--container" id="behandlungs-prozess">
      <div className="process--title page-title color-dark float-right">{behandlungsProzessItems[0]?.title}</div>
      <div className="process--content">
        <div className="process--item-wrap">{processItems && processItems[0]}</div>
        <div className="process--item-wrap">{processItems && processItems[1]}</div>
        <div className="process--item-wrap">{processItems && processItems[2]}</div>
        <div className="process--item-wrap">{processItems && processItems[3]}</div>
      </div>
      {!isMobile &&
        <div className="process--footer">
          <div className="process--phone"></div>
          <div className="process--spacer"></div>
          <div className="process--eye"></div>
          <div className="process--spacer"></div>
          <div className="process--plan"></div>
          <div className="process--spacer"></div>
          <div className="process--todo"></div>
        </div>}
    </div>
  )

}

export default BehandlungsProzess;
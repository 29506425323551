import { getData } from "../helpers/data";

export async function getWelcomeData(lang) {
  const query = `
    {
        aboutMeCollection(locale: "${lang}") {
          items {
            leftTitle
            leftDescription {
              json
            }
            rightTitle
            rightDescription {
              json
            }
            image {
              title
              url
            }
          }
        }
    }
`
  const data = await getData(query);
  return data.data.aboutMeCollection.items[0];
}
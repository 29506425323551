import { getData } from "../helpers/data";

export async function getImpressumData() {
  const query = `
    {
        impressumCollection {
            items {
                title
                paragraphsWithTitlesCollection {
                    items {
                        title
                        subtitle
                        paragraphText {
                            json
                        }
                    }
                }
            }
        }
    }
`
  const data = await getData(query);
  return data.data.impressumCollection.items[0];
}
import { useEffect, useState } from "react";
import { getSentences } from "../api/sentence-api";

export function useSentenceDict() {
  const [dict, setDict] = useState("");

  const setAllSentences = async () => {
    const data = await getSentences();
    buildDict(data);
  }

  useEffect(() => {
    setAllSentences();
  }, []);

  const buildDict = (data) => {
    const dictCopy = {};
    data.sentencesInGerman.forEach((sentence, index) => {
      dictCopy[sentence.sentence] = data.sentencesInEnglish[index].sentence
    })
    setDict(dictCopy);
  }

  return { allSentencesDict: dict }


}
import { useEffect, useState } from "react";
import 'reactjs-popup/dist/index.css';
import { ReactComponent as MenuIcon } from "../../icons/menu.svg";
import SideMenuPopup from "./SideMenuPopup";
import "./sideMenuTrigger/styles.scss";

function SideMenuTrigger() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      document.getElementsByTagName("body")[0].style = "overflow: hidden; position: fixed";
    } else {
      document.getElementsByTagName("body")[0].style = "";
    }
  }, [open])

  return <>
    <MenuIcon onClick={() => setOpen(true)} className="side_menu_trigger menu-icon" />
    <SideMenuPopup open={open} setOpen={setOpen} />
  </>

}

export default SideMenuTrigger;
import { getData } from "../helpers/data";

export async function getShopData(lang) {
  const query = `
    {
        shopCollection(locale: "${lang}") {
          items {
            title
            description {
              json
            }
          }
        }
    }
`
  const data = await getData(query);
  return data.data.shopCollection.items[0];
}
import { getData } from "../helpers/data";

export async function getCoreValuesData(lang) {
  const query = `
    {
        coreValuesCollection(locale: "${lang}") {
            items {
                title
                paragraphsWithTitlesCollection {
                    items {
                        title
                        paragraphText {
                            json
                        }
                    }
                }
            }
        }
    }
`
  const data = await getData(query);
  return data.data.coreValuesCollection.items;
}
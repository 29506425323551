import { useCallback, useContext, useEffect, useState } from "react";

import { getHomepageData } from "../api/homepage-api";

import AppContext from "../context/AppContext";

import SocialMediaLinks from "../components/commons/SocialMediaLinks";
import "../components/home/styles.scss";
import Menu from "../components/commons/Menu";
import ComponentTypes from "../constants/ComponentTypes";

import styled from 'styled-components';

function Home() {
  const [homepageItems, setHomepageItems] = useState([]);
  const context = useContext(AppContext);
  const { isMobile } = context;

  const StyledHomepageContainer = styled.div`
        background-image: url(${isMobile ? homepageItems?.backgroundImageMobile?.url : homepageItems?.backgroundImage?.url});
    `;

  const setData = useCallback(async () => {
    const data = await getHomepageData(context.store.language);
    setHomepageItems(data);
  }, [context.store.language]);

  useEffect(() => {
    setData()
  }, [setData])

  if (!homepageItems) {
    return <div>Loading...</div>;
  }
  return (
    <StyledHomepageContainer className="homepage-container page">
      <Menu componentType={ComponentTypes.HP} />
      <div className="content">
        <h1 className="homepage-title">{homepageItems.title}</h1>
        <div className="homepage-footer">
          <p className="subtitle text-xlarge">{homepageItems.subtitle}</p>
          <SocialMediaLinks width={isMobile ? 30 : 40} height={isMobile ? 30 : 40} />
        </div>
      </div>
    </StyledHomepageContainer >
  )
}

export default Home;
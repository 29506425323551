import { getData } from "../helpers/data";

export async function getHomepageData(lang) {
  const query = `
    {
        homepageCollection(locale: "${lang}") {
            items {
                title
                subtitle
                backgroundImage {
                    url
                }
                backgroundImageMobile {
                    url
                }
            }
        }
    }
`
  const data = await getData(query);
  return data.data.homepageCollection.items[0];
}
import { useState, useContext, useEffect, useCallback } from "react";

import AppContext from "../context/AppContext";
import { getShopData } from "../api/shop-api";

import SocialMediaLinks from "../components/commons/SocialMediaLinks";

import Menu from "../components/commons/Menu";
import ComponentTypes from "../constants/ComponentTypes";

import "../components/shop/styles.scss";

function ShopPage() {
  const [shopData, setShopData] = useState([]);
  const context = useContext(AppContext);
  const { isMobile } = context;

  const setData = useCallback(async () => {
    const data = await getShopData(context.store.language);
    setShopData(data);
  }, [context.store.language]);

  useEffect(() => {
    setData();
  }, [setData]);


  return (
    <div className={`shop page ${isMobile ? "" : "bg-top"}`}>
      <Menu componentType={ComponentTypes.SHOP} />
      <div className="page-content bg-dog-image">
        <div className="shop-title color-light">{shopData?.title}</div>
        <div className="shop_content">
          <div className="shop_content__left">
            <div className="text text-green description">
              {shopData?.description?.json.content.map((cont, i) => <p key={i}>{cont.content[0].value}</p>)}
            </div>
            <SocialMediaLinks width={isMobile ? 30 : 40} height={isMobile ? 30 : 40} />
          </div>
        </div>
      </div>


    </div>
  );
}

export default ShopPage;
import {getData} from "../helpers/data";

export async function getSentences() {
  const queryForEnglish = `
  {
      sentenceCollection(locale: "en-US") {
        items {
          sentence
        }
      }
  }
`

const queryForGerman = `
  {
      sentenceCollection(locale: "de-DE") {
        items {
          sentence
        }
      }
  }
`
  const dataInEnglish = await getData(queryForEnglish);
  const dataInGerman = await getData(queryForGerman);

  const sentencesInEnglish = dataInEnglish.data.sentenceCollection.items;
  const sentencesInGerman = dataInGerman.data.sentenceCollection.items;

  return {sentencesInGerman, sentencesInEnglish}
}